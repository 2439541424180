export function modal(modalSelector, buttonSelector) {

  const modal = document.querySelector(modalSelector)
  const buttons = document.querySelectorAll(buttonSelector)
  const closeButton = document.querySelector('.close-modal')
  const closeBackground = document.querySelector('.modal-background')

  if (buttons && modal) {
    buttons.forEach(function (button) {
      button.addEventListener('click', function (event) {
        event.stopPropagation()
        modal.classList.toggle('is-active')
      })
    })
  }

  if (closeBackground) {
    closeBackground.addEventListener('click', function (event) {
      event.stopPropagation()
      modal.classList.remove('is-active')
    })
  }

  if (closeButton) {
    closeButton.addEventListener('click', function (event) {
      event.stopPropagation()
      modal.classList.remove('is-active')
    })
  }
}