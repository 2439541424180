import { brandSlider, warrantySlider, indexSlider, productSlider } from './sliders'
import { bulmaTabs } from './tabs'
import { dropdown } from './dropdown'
import { modal } from './modal'
import { navbar } from './navbar'
import { catalogToShort } from './catalog'
import FsLightbox from 'fslightbox'


indexSlider('#index-slider')
brandSlider('#brand-slider')
warrantySlider('#warranty-slider')
productSlider('.gallery-thumbs', '.gallery-top')

catalogToShort('.short-catalog-mobile > div', 4)

bulmaTabs('.tabs li', '.tab-content')
dropdown('.dropdown')
modal('.modal-callback', '.modal-callback-button')
navbar('.navbar-mobile-gamburger', '.navbar-mobile-popup', '.navbar-mobile-close')