export function dropdown(selector) {
  const dropdown = document.querySelector(selector)

  if (dropdown) {
    dropdown.addEventListener('click', function (event) {
      event.stopPropagation()
      dropdown.classList.toggle('is-active');
    })

    window.onclick = function (event) {
      if (!event.target.matches('.dropdown')) {
        dropdown.classList.remove('is-active');
      }
    }
  }
}