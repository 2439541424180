
export function bulmaTabs(links, content) {
  const tabs = document.querySelectorAll(links)
  const tabsContent = document.querySelectorAll(content)

  const deactvateAllTabs = function () {
    tabs.forEach(function (tab) {
      tab.classList.remove('is-active')
    })
  }

  const hideTabsContent = function () {
    tabsContent.forEach(function (tabContent) {
      tabContent.classList.remove('is-active')
    })
  }

  const activateTabsContent = function (tab) {
    tabsContent[getIndex(tab)].classList.add('is-active')
  }

  const getIndex = function (el) {
    return [...el.parentElement.children].indexOf(el)
  }

  tabs.forEach(function (tab) {
    tab.addEventListener('click', function () {
      deactvateAllTabs()
      hideTabsContent()
      tab.classList.add('is-active')
      activateTabsContent(tab)
    })
  })
}