export function catalogToShort(selector, quantity) {
  function hiddenItems() {
    const elements = document.querySelectorAll(selector)
    const media = window.matchMedia('(max-width: 1019px)').matches
    
    if (elements) {
      elements.forEach(function (element, index) {
        if (index >= quantity) {
          if (media) element.style.display = 'none'
          else element.style.removeProperty('display')
        }
      })
    }
  }

  window.onresize = function() {
    hiddenItems()
  }
  hiddenItems()
}
