export function navbar(gambergerSelector, navigationSelector, closeSelector) {
  const button = document.querySelector(gambergerSelector)
  const navigation = document.querySelector(navigationSelector)
  const close = document.querySelector(closeSelector)

  if (button && navigation && close) {
    button.addEventListener('click', function (event) {
      event.stopPropagation()
      navigation.classList.toggle('is-active')
    })
  
    close.addEventListener('click', function (event) {
      event.stopPropagation()
      navigation.classList.remove('is-active')
    })
  }
}