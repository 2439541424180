import { Swiper, Navigation, Pagination, Thumbs } from 'swiper'

Swiper.use([Navigation, Pagination, Thumbs])

export function brandSlider(selector) {
  new Swiper(selector, {
    loop: true,
    spaceBetween: 40,
    slidesPerView: 3,
    navigation: {
      nextEl: '.swiper-custom-button-next',
      prevEl: '.swiper-custom-button-prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 5,
        spaceBetween: 10
      },
      1232: {
        slidesPerView: 6,
      }
    }
  })
}

export function warrantySlider(selector) {
  new Swiper(selector, {
    loop: false,
    spaceBetween: 40,
    slidesPerView: 2,
    navigation: {
      nextEl: '.swiper-custom-button-next',
      prevEl: '.swiper-custom-button-prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 10
      },
      1232: {
        slidesPerView: 5,
      }
    }
  })
}

export function indexSlider(selector) {
  new Swiper(selector, {
    loop: false,
    pagination: {
      el: '.swiper-custom-pagination'
    },
    navigation: {
      nextEl: '.swiper-custom-button-next',
      prevEl: '.swiper-custom-button-prev'
    },
  })
}

export function productSlider(thumbs, image) {
  const productThumbSlider = new Swiper(thumbs, {
    slidesPerView: 'auto',
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
  })
  
  new Swiper(image, {
    spaceBetween: 10,
    thumbs: {
      swiper: productThumbSlider
    }
  })
}